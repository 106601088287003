<template>
  <div class="bg bg3">
    <div class="class--con">
      <class-but @forward="forward"/>
    </div>
  </div>
</template>

<script>

  import ClassBut from "@/views/conference/ConferenceItem/classBut.vue";
  import service from "@/utils/axios";
  export default {
    name: "eighteenPage",
    components: {ClassBut},
    data(){
      return{
        imgUrl:{}
      }
    },
    created() {
      this.gitApicourseTalk()
    },
    methods:{
      forward(){
        this.$router.push({
          name: "PageNineteenth",
          query: this.$route.query,
        })
      },
      async gitApicourseTalk(){
        let res = await service.post(`/dwart/an_teaching/hbk/v1/search/searchExpandCourse`, {
          classId: this.$route.query.classId
        })
        if (!res._show) {
          await this.$router.replace({
            name: "PageNineteenth",
            query: this.$route.query,
          })
          return
        }
        // this.imgUrl = { backgroundImage :`url("${ res.imgUrl}")`}
      },
    }
  }
</script>

<style lang="less" scoped >
  @import "reportClass.less";

</style>
